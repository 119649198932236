
import {useI18n} from "vue-i18n";
import {Actions} from "@/store/enums/StoreEnums";
import {useStore} from "vuex";
import {onMounted, ref} from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

interface formSettingsData {
  pay_interval: number;
  wait_interval_in: string;
  wait_interval_value: number;
  pay_interval_in: string;
  pay_interval_value: number;
  yookassa_shopid: string;
  yookassa_shopid_test: string;
  live_yookassa: string;
  live_yookassa_test: string;
  service_type: any;
  language: string;
  pay_name: string;
  pay_sum: string;
  subIntervalSlider: number;
  payIntervalSlider: number;
  maxIntervalSlider_value: number,
  maxIntervalSlider: string,
}

export default {
  name: "forms-tab",
  components: {},
  props: {},
  state: {},
  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const submitButton = ref<HTMLElement | null>(null);
    const сourses = ref([]);
    const tabName = ref();

    let settingsData = ref<formSettingsData>({
      pay_interval: 1,
      wait_interval_in: "days",
      wait_interval_value: 1,
      pay_interval_in: "days",
      pay_interval_value: 1,
      yookassa_shopid: "",
      yookassa_shopid_test: "",
      live_yookassa: "",
      live_yookassa_test: "",
      service_type: [],
      language: "en",
      pay_name: "",
      pay_sum: "",
      subIntervalSlider: 1,
      payIntervalSlider: 1,
      maxIntervalSlider_value: 1,
      maxIntervalSlider: "days",
    });

    let maxDaysSlider = ref(31),
        maxMonthSlider = ref(12);

    onMounted(() => {
      // Get all settings

      store.dispatch(Actions.GET_SETTINGS)
          .then(() => {
            settingsData.value = store.getters.getterSettings;
      });

      // Get courses data
      store
          .dispatch(Actions.CURSES_GET_ALL)
          .then(() => {
            const data = store.getters.getAllCurses;
            сourses.value = data.map((item) => {
              return {
                isAvailable: item.available,
                _id: item._id,
                course_name: item.course_name ? item.course_name : "-",
                course_description: item.course_description
                    ? item.course_description
                    : "-",
                course_icon: item.course_icon ? item.course_icon : "-",
                payment_cost: item.payment_cost ? item.payment_cost : "-",
              };
            });
          });

    });

    const onSubmitSettings = () => {
      // Clear existing errors
      store.dispatch(Actions.CLEAR_SETTING_ERROR);

      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Dummy delay
      setTimeout(() => {
        if (сourses.value) {
          сourses.value.map(item => {
            store.dispatch(Actions.SET_COURSES, item);
          });
        }

        store
          .dispatch(Actions.SET_SETTINGS, settingsData.value)
          .then(() => {
            Swal.fire({
              text: t("processDone"),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t("good"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            });
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.settingsError,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t("tryAgain"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        //Deactivate indicator
        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 1000);
    };

    const courseAvailableChange = (courseID) => {
      store.dispatch(Actions.CHANGE_COURSE_AVAILABLE, courseID)
    };

    /**
     * Set tab name
     * Using for button logic
     */
    const setTabName = (name) => {
      tabName.value = name;
    };

    return {
      t,
      onSubmitSettings,
      setTabName,
      courseAvailableChange,
      settingsData,
      submitButton,
      сourses,
      maxDaysSlider,
      maxMonthSlider,
      tabName,
    };
  },
};
