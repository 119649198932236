<template>
  <forms-tab/>
</template>

<script>
import FormsTab from "../../components/settings/FormsTab";
import { setCurrentPageBreadcrumbs } from "../../core/helpers/breadcrumb";

export default {
  name: "settings",
  components: {FormsTab},
  setup() {
    setCurrentPageBreadcrumbs("Settings", []);
  }
}
</script>

<style scoped>

</style>